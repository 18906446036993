<template>
    <v-flex class="display-block">
        <h3>Inventory</h3>
        <v-card flat>
            <v-card-text>
                <row-item label="Product : " :value="productInventory.title"></row-item>
                <row-item label="Stock : " :value="productInventory.stock.toString()"></row-item>
                <row-item label="Reserve Stock : " :value="productInventory.reserveStock.toString()"></row-item>
            </v-card-text>
        </v-card>
        <v-card flat>
            <v-card-text>
                <v-flex text-right>
                    <v-btn :style="themeInverted" @click="readStockHistory()" outlined>Show History</v-btn>
                </v-flex>
                <br>
                <v-flex text-right>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th class="text-left">Date</th>
                                <th class="text-left">Code</th>
                                <th class="text-left">Title</th>
                                <th class="text-right">Event</th>
                                <th class="text-right">Order No</th>
                                <th class="text-right">Opening Stock</th>
                                <th class="text-right">Purchased Stock</th>
                                <th class="text-right">Sold Stock</th>
                                <th class="text-right">Current Stock</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.code">
                                <td class="text-left">{{item.date | dateFormat}}</td>
                                <td class="text-left">{{item.code}}</td>
                                <td class="text-left">{{item.name}}</td>
                                <td>{{item.event}}</td>
                                <td>{{item.orderNo}}</td>
                                <td>{{item.openingStock}}</td>
                                <td>{{item.purchasedStock}}</td>
                                <td>{{item.soldStock}}</td>
                                <td>{{item.currentStock}}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-flex>
            </v-card-text>
        </v-card>
        <v-flex text-right><v-btn @click="$router.go(-1)" class="ma-2" :style="theme">Back</v-btn></v-flex>
    </v-flex>
</template>

<script>
import RowItem from '@/components/RowItem'
import appConstants from '@/utils/appConstants'
import axios from 'axios'
export default {
    components: {
        RowItem,
    },
    data() {
        return {
            id:0,
            productInventory:{
                title:'',
                stock:0,
                reserveStock:0
            },
            items:[],
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
                this.getItem(appConstants.INVENTORY_API + "/" + this.id).then(data=>{
                    this.productInventory=data[0]
                })
        },
        async readStockHistory(){
            this.items = await this.getItem(appConstants.STOCK_REPORT+"/"+this.productInventory.code)
        }
    }
}
</script>
